import React, { useCallback, useEffect, type FC } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

import InformationIcon from '@atlaskit/icon/core/information';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';

import type { FlagsStateContainer } from '@confluence/flags';
import { withFlags } from '@confluence/flags';
import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';
import { fg } from '@confluence/feature-gating';
import {
	createSingleQueryParamHook,
	useRouteActions,
} from '@confluence/route-manager/entry-points/RouteState';
import { getMonitoringClient } from '@confluence/monitoring';
import { Attribution } from '@confluence/error-boundary';
import { useSessionData } from '@confluence/session-data';

import type {
	RequestAccessMutation as RequestAccessMutationResponse,
	RequestAccessMutationVariables,
} from '../__types__/RequestAccessMutation';
import { AccessType } from '../__types__/RequestAccessMutation';

import { useRequestPopupFlags } from './useRequestPopupFlags';
import { useUserPageAccessType } from './useUserPageAccessType';

const i18n = defineMessages({
	title: {
		id: 'grant-access-dialog.request-access-popup.flag-title',
		defaultMessage: 'Ask to edit this content?',
		description: 'Title for the popup shown when user does not have edit access',
	},
	content: {
		id: 'grant-access-dialog.request-access-popup.flag-content',
		defaultMessage: 'You’ll get an email when your request is approved.',
		description: 'Content for the popup shown when user does not have edit access',
	},
	requestBtn: {
		id: 'grant-access-dialog.request-access-popup.approve-btn',
		defaultMessage: 'Ask to edit',
		description: 'Request access button text',
	},
});

// Only allowed value is the string `true`
const QUERY_PARAM_NAME = 'redirectedFromRestrict';
const useQueryParamRedirectedFromRestrict = createSingleQueryParamHook(QUERY_PARAM_NAME);

// Flag id
const FLAG_ID = 'request-edit-access';

// GraphQL endpoints - not reusable due to eslint rule
const RequestAccessPopupRequestAccessMutation = gql`
	mutation RequestAccessPopupRequestAccessMutation($accessType: AccessType!, $pageId: String!) {
		requestPageAccess(requestPageAccessInput: { accessType: $accessType, pageId: $pageId }) {
			displayName
		}
	}
`;

/**
 * Purpose:
 * - Display a popup to the user when they are redirected from a restricted page.
 * - Allow the user to request access to the restricted page.
 *
 * Protocol:
 * To display the request access popup, the query param `redirectedFromRestrict` should be set to `true`.
 * After a decision is made, the query param should be removed.
 *
 * Usage:
 * This hook can only be used inside the context of FlagsProvider.
 */
export const useRequestAccessPopup = (contentId: string, flags: FlagsStateContainer) => {
	const { edition } = useSessionData();
	const isRedirectedFromRestrict = useQueryParamRedirectedFromRestrict();
	const shouldSkip = Boolean(
		// TODO Remove the code as part of the FG cleanup 'auto_routing_to_view_mode'
		!fg('auto_routing_to_view_mode') ||
			// No SSR
			// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
			process.env.REACT_SSR ||
			// Not available in Free edition
			edition === ConfluenceEdition.FREE ||
			// No particular query param
			!isRedirectedFromRestrict,
	);

	const { showSuccessFlag, showErrorFlag } = useRequestPopupFlags(flags);
	const { setQueryParams } = useRouteActions();
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { accessTypes, error: contentPermissionError } = useUserPageAccessType(contentId);
	const [requestAccessMutation, { error: requestAccessError }] = useMutation<
		RequestAccessMutationResponse,
		RequestAccessMutationVariables
	>(RequestAccessPopupRequestAccessMutation, {
		onCompleted: () => {
			void showSuccessFlag();
		},
		onError: () => {
			void showErrorFlag();
		},
	});

	const reportPopupEvent = useCallback(
		(analyticEvtType: 'request' | 'dismiss') => {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId:
						analyticEvtType === 'request' ? 'requestEditAction' : 'dismissRequestEditPopupAction',
					source: 'requestAccessPopup',
					attributes: {
						contentId,
						accessTypes: accessTypes.join(','),
					},
				},
			}).fire();
			// Decision has been made. No more popups needed.
			setQueryParams({ [QUERY_PARAM_NAME]: undefined });
		},
		[setQueryParams, contentId, createAnalyticsEvent, accessTypes],
	);

	const requestAccess = useCallback(() => {
		// Dismiss the popup first (unblocking the UI)
		void flags.hideFlag(FLAG_ID);
		reportPopupEvent('request');
		// Send the request
		return requestAccessMutation({
			variables: {
				accessType: AccessType.EDIT,
				pageId: contentId,
			},
		});
	}, [contentId, reportPopupEvent, requestAccessMutation, flags]);

	// Error handling
	useEffect(() => {
		if (shouldSkip) return;

		if (contentPermissionError) {
			getMonitoringClient().submitError(contentPermissionError, {
				attribution: Attribution.PERMISSIONS_EXPERIENCE,
			});
		}

		if (requestAccessError) {
			getMonitoringClient().submitError(requestAccessError, {
				attribution: Attribution.PERMISSIONS_EXPERIENCE,
			});

			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'errored',
					actionSubject: 'requestEditSent',
					source: 'requestEditPopup',
					attributes: {
						contentId,
						accessTypes: accessTypes.join(','),
					},
				},
			}).fire();
		}
	}, [
		accessTypes,
		contentPermissionError,
		requestAccessError,
		shouldSkip,
		createAnalyticsEvent,
		contentId,
	]);

	// When the component loads
	useEffect(() => {
		if (shouldSkip) return;

		if (accessTypes && accessTypes.includes('VIEW') && !accessTypes.includes('EDIT')) {
			// Display the flag
			void flags.showFlag({
				id: FLAG_ID,
				title: formatMessage(i18n.title),
				customIcon: (
					<InformationIcon
						color={token('color.text.information')}
						label={formatMessage(i18n.title)}
						spacing="spacious"
					/>
				),
				description: formatMessage(i18n.content),
				actions: [
					{
						content: formatMessage(i18n.requestBtn),
						onClick: requestAccess,
					},
				],
				onClose: () => reportPopupEvent('dismiss'),
			});

			// Event
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'viewed',
					actionSubject: 'flag',
					actionSubjectId: 'showRequestToEditPopup',
					source: 'requestAccessPopup',
					attributes: {
						contentId,
						accessTypes: accessTypes.join(','),
					},
				},
			}).fire();
		}
	}, [
		reportPopupEvent,
		requestAccess,
		flags,
		formatMessage,
		accessTypes,
		shouldSkip,
		contentId,
		createAnalyticsEvent,
	]);

	useEffect(() => {
		return () => {
			void flags.hideFlag(FLAG_ID);
		};
	}, [flags]);
};

// Currently there's a mix use of Atlaskit flags and Confluence flags.
// This component is using Confluence flags.
const RequestAccessPopupFlagWraper: FC<{ contentId: string; flags: FlagsStateContainer }> = ({
	contentId,
	flags,
}) => {
	useRequestAccessPopup(contentId, flags);
	return null;
};

export const RequestAccessPopupComponent = withFlags(RequestAccessPopupFlagWraper);
