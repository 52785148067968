import React, { PureComponent } from 'react';
import { Mutation } from 'react-apollo';
import type { WrappedComponentProps } from 'react-intl-next';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl-next';
import { styled } from '@compiled/react';
// We have deprecated unstated. Please use react-sweet-state instead

import { Subscribe } from 'unstated';

import { withAnalyticsEvents, type WithAnalyticsEventsProps } from '@atlaskit/analytics-next';
import Heading from '@atlaskit/heading';
import { token } from '@atlaskit/tokens';
import Button from '@atlaskit/button';
import Popup from '@atlaskit/popup';

import { FlagsStateContainer } from '@confluence/flags';
import { ProfileLink, ProfileAvatar } from '@confluence/profile';

import type { GrantAccessDialogProps } from './types';
import { experimentalGrantAccessMutation as GrantAccessMutation } from './GrantAccessMutation.experimentalgraphql';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DialogContainer = styled.div({
	width: '450px',
	padding: `${token('space.200')} ${token('space.300')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MessageContainer = styled.p({
	font: token('font.body'),
	marginTop: token('space.150'),
	marginBottom: token('space.200'),
	display: 'flex',
	alignItems: 'center',
	gap: token('space.050'),
});
MessageContainer.displayName = 'MessageContainer';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ActionsContainer = styled.div({
	font: token('font.body'),
	display: 'flex',
	justifyContent: 'end',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DialogAnchor = styled.div({
	display: 'inline-block',
	position: 'relative',
});

const i18n = defineMessages({
	dialogTitleViewRequest: {
		id: 'grant-access-dialog.dialog.title.view',
		defaultMessage: 'View access request',
		description: 'title for dialog to grant view access to content when requested',
	},
	dialogTitleEditRequest: {
		id: 'grant-access-dialog.dialog.title.edit',
		defaultMessage: 'Edit access request',
		description: 'title for dialog to grant edit access to content when requested',
	},
	grantViewAccess: {
		id: 'grant-access-dialog.dialog.action.grant.view',
		defaultMessage: 'Grant view access',
		description: 'Button label to grant view access request to content',
	},
	grantEditAccess: {
		id: 'grant-access-dialog.dialog.action.grant.edit',
		defaultMessage: 'Grant edit access',
		description: 'Button label to grant edit access request to content',
	},
	denyAccess: {
		id: 'grant-access-dialog.dialog.action.deny',
		defaultMessage: 'Deny',
		description: 'Button label to deny access request to content',
	},
	successTitle: {
		id: 'grant-access-dialog.success.title',
		defaultMessage: 'Access request granted',
		description: 'For content permissions',
	},
	errorTitle: {
		id: 'grant-access-dialog.error.title',
		defaultMessage: 'Access request error',
		description: 'For content permissions',
	},
	//412
	noMailServer: {
		id: 'grant-access-dialog.error.no-mail-server',
		defaultMessage:
			'Access was granted, but there is not a mail server configured so the notification could not be sent.',
		description: 'For content permissions',
	},
	//502
	sendEmailError: {
		id: 'grant-access-dialog.error.send-email',
		defaultMessage:
			'Access was granted, but an unexpected error happened while sending the notification.',
		description: 'For content permissions',
	},
	//every other error
	genericError: {
		id: 'grant-access-dialog.error.generic',
		defaultMessage: 'Sorry, there was an unexpected error while granting access.',
		description: 'For content permissions',
	},
	//202
	alreadyGranted: {
		id: 'grant-access-dialog.success.already-granted',
		defaultMessage: 'Access was already granted to the user.',
		description: 'For content permissions',
	},
	//other success
	genericSuccess: {
		id: 'grant-access-dialog.success.generic',
		defaultMessage: 'Access was granted, a notification to the user will be sent.',
		description: 'For content permissions',
	},
	requestedViewAccess: {
		id: 'grant-access-dialog.dialog.message.request.view.access.to.content',
		defaultMessage: '{person} requested view access to <b>{contentTitle}</b>.',
		description: 'Person trying to request view access to content',
	},
	requestedEditAccess: {
		id: 'grant-access-dialog.dialog.message.request.edit.access.to.content',
		defaultMessage: '{person} requested edit access to <b>{contentTitle}</b>.',
		description: 'Person trying to request view access to content',
	},
});

class GrantAccessDialogComponent extends PureComponent<
	GrantAccessDialogProps & WithAnalyticsEventsProps & WrappedComponentProps
> {
	static defaultProps = {
		accessType: 'view',
		isOpen: false,
		onActionClick: () => {},
	};

	componentDidMount() {
		const { createAnalyticsEvent } = this.props;

		if (createAnalyticsEvent) {
			const { attributes, source } = this.generateAnalyticsProps();

			createAnalyticsEvent({
				type: 'sendScreenEvent',
				data: {
					name: 'grantAccessScreen',
					source,
					attributes,
				},
			}).fire();
		}
	}

	generateAnalyticsProps = () => ({
		source: 'grantAccessDialog',
		attributes: {
			accessType: this.props.accessType,
		},
	});

	generateGrantClickHandler(mutateGrantAccess) {
		const { accessType, accountId, onActionClick, contentId, createAnalyticsEvent } = this.props;
		const { attributes, source } = this.generateAnalyticsProps();

		return () => {
			createAnalyticsEvent &&
				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						action: 'clicked',
						actionSubject: 'button',
						actionSubjectId: 'grantAccess',
						source,
						attributes,
					},
				}).fire();

			mutateGrantAccess({
				variables: {
					contentId,
					accessType,
					accountId,
				},
			});

			if (onActionClick) onActionClick();
		};
	}

	generateGrantAccessErrorHandler(flagsContainer) {
		const {
			intl: { formatMessage },
			createAnalyticsEvent,
		} = this.props;
		const { attributes, source } = this.generateAnalyticsProps();
		const title = formatMessage(i18n.errorTitle);

		return (error) => {
			const errorStatus = error?.graphQLErrors?.[0]?.message;
			let description;
			if (errorStatus === '412') {
				description = formatMessage(i18n.noMailServer);
			} else if (errorStatus === '502') {
				description = formatMessage(i18n.sendEmailError);
			} else {
				description = formatMessage(i18n.genericError);
			}

			// It's not clear whether this codepath is actually used for expected errors,
			// as comments elsewhere note that the experimental resolver results in
			// responseStatus=null for error state, which is handled by the success path.
			// Analytics should help us determine how/whether the error handling here is valid.
			createAnalyticsEvent &&
				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						action: 'shown',
						actionSubject: 'error',
						actionSubjectId: 'grantAccess',
						source,
						attributes: {
							...attributes,
							errorStatus,
						},
					},
				}).fire();

			flagsContainer.showErrorFlag({
				title,
				description,
			});
		};
	}

	generateGrantAccessSuccessHandler(flagsContainer) {
		const {
			intl: { formatMessage },
			createAnalyticsEvent,
		} = this.props;
		const { attributes, source } = this.generateAnalyticsProps();

		return (response) => {
			const responseStatus = response?.experimentalGrantAccess?.responseStatus;

			if (!responseStatus) {
				createAnalyticsEvent &&
					createAnalyticsEvent({
						type: 'sendTrackEvent',
						data: {
							action: 'failed',
							actionSubject: 'accessRequest',
							actionSubjectId: 'grantAccess',
							source,
							attributes,
						},
					}).fire();

				// experimental resolver throwing new error will result in responseStatus = null
				flagsContainer.showErrorFlag({
					title: formatMessage(i18n.errorTitle),
					description: formatMessage(i18n.genericError),
				});
				return;
			}

			let description;
			if (responseStatus === '202') {
				description = formatMessage(i18n.alreadyGranted);
			} else {
				description = formatMessage(i18n.genericSuccess);
			}

			createAnalyticsEvent &&
				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						action: 'granted',
						actionSubject: 'access',
						actionSubjectId: 'grantAccess',
						source,
						attributes,
					},
				}).fire();

			flagsContainer.showSuccessFlag({
				title: formatMessage(i18n.successTitle),
				description,
			});
		};
	}

	generateDenyAccessHandler() {
		const { onActionClick, createAnalyticsEvent } = this.props;

		const { attributes, source } = this.generateAnalyticsProps();
		return () => {
			createAnalyticsEvent &&
				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						action: 'clicked',
						actionSubject: 'button',
						actionSubjectId: 'denyAccess',
						source,
						attributes,
					},
				}).fire();
			if (onActionClick) onActionClick();
		};
	}

	getContent(flagsContainer) {
		const { displayName, contentTitle, accessType, accountId, profilePhoto } = this.props;

		const requestedAccessCopy =
			accessType === 'view' ? i18n.requestedViewAccess : i18n.requestedEditAccess;
		const dialogTitle =
			accessType === 'view' ? i18n.dialogTitleViewRequest : i18n.dialogTitleEditRequest;
		const grantAccessButtonText =
			accessType === 'view' ? i18n.grantViewAccess : i18n.grantEditAccess;

		return (
			<DialogContainer>
				<Heading size="large" as="h3">
					<FormattedMessage {...dialogTitle} />
				</Heading>
				<MessageContainer>
					<ProfileAvatar userId={accountId} name={displayName} src={profilePhoto} size="xsmall" />
					<FormattedMessage
						{...requestedAccessCopy}
						values={{
							person: <ProfileLink userId={accountId} fullName={displayName} />,
							contentTitle,
							b: (chunks) => <b>{chunks}</b>,
						}}
					/>
				</MessageContainer>
				<ActionsContainer>
					<Button
						appearance="subtle-link"
						onClick={this.generateDenyAccessHandler()}
						testId="deny-button"
					>
						<FormattedMessage {...i18n.denyAccess} />
					</Button>
					<Mutation
						mutation={GrantAccessMutation}
						onError={this.generateGrantAccessErrorHandler(flagsContainer)}
						onCompleted={this.generateGrantAccessSuccessHandler(flagsContainer)}
					>
						{(mutateGrantAccess) => (
							<Button
								onClick={this.generateGrantClickHandler(mutateGrantAccess)}
								testId="grant-button"
								appearance="primary"
							>
								<FormattedMessage {...grantAccessButtonText} />
							</Button>
						)}
					</Mutation>
				</ActionsContainer>
			</DialogContainer>
		);
	}

	render() {
		const { children, isOpen } = this.props;

		return (
			<Subscribe to={[FlagsStateContainer]}>
				{(flagsContainer: FlagsStateContainer) => (
					<Popup
						isOpen={Boolean(isOpen)}
						content={() => this.getContent(flagsContainer)}
						trigger={(triggerProps) => (
							<DialogAnchor data-test-id="inline-dialog-anchor" {...triggerProps}>
								{children}
							</DialogAnchor>
						)}
					/>
				)}
			</Subscribe>
		);
	}
}
export const GrantAccessDialog = withAnalyticsEvents()(injectIntl(GrantAccessDialogComponent));
