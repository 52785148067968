import gql from 'graphql-tag';
import { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { getMonitoringClient } from '@confluence/monitoring';
import { Attribution } from '@confluence/error-boundary';

import type {
	RequestViewQuery as RequestViewQueryResponse,
	RequestViewQueryVariables,
} from '../__types__/RequestViewQuery';

const useUserPageAccessTypeContentOperationsQuery = gql`
	query useUserPageAccessTypeContentOperationsQuery($id: ID!) {
		singleContent(id: $id) {
			id
			type
			operations {
				operation
				targetType
			}
		}
	}
`;

// This access type is in the context of Grant Access and Access Request feature.
// To get specific permissions for a user, check the operations array.
export type UserPageAccessType = 'EDIT' | 'VIEW';

export const useUserPageAccessType = (
	contentId: string,
): { accessTypes: UserPageAccessType[]; error: Error | undefined; loading: boolean } => {
	const { data, loading, error } = useQuery<RequestViewQueryResponse, RequestViewQueryVariables>(
		useUserPageAccessTypeContentOperationsQuery,
		{
			variables: {
				id: contentId,
			},
		},
	);

	useEffect(() => {
		if (error) {
			getMonitoringClient().submitError(error, {
				attribution: Attribution.PERMISSIONS_EXPERIENCE,
			});
		}
	}, [error]);

	const accessTypes: UserPageAccessType[] = useMemo(() => {
		const contentNode = data?.singleContent;
		const operations = contentNode?.operations ?? [];
		const contentType = contentNode?.type ?? undefined;

		const result: UserPageAccessType[] = [];
		// Check if the user has the view permission
		if (operations.find((op) => op?.operation === 'read' && op.targetType === contentType)) {
			result.push('VIEW');
		}
		// Check if the user has the edit permission
		if (operations.find((op) => op?.operation === 'update' && op.targetType === contentType)) {
			result.push('EDIT');
		}
		return result;
	}, [data]);

	return {
		accessTypes,
		error,
		loading,
	};
};
