import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import ErrorIcon from '@atlaskit/icon/core/error';
import CheckCircleIcon from '@atlaskit/icon/core/success';
import { token } from '@atlaskit/tokens';

import type { FlagsStateContainer } from '@confluence/flags';

const i18n = defineMessages({
	successTitle: {
		id: 'grant-access-dialog.request-access-popup.success-flag-title',
		defaultMessage: 'Request sent',
		description: 'Title for the success flag shown when user has sent a request for edit access',
	},
	successContent: {
		id: 'grant-access-dialog.request-access-popup.success-flag-content',
		defaultMessage:
			'We’ve asked the content owner to give you edit access. You’ll get an email once the request is approved.',
		description: 'Text for the success flag shown when user has sent a request for edit access',
	},
	errorTitle: {
		id: 'grant-access-dialog.request-access-popup.error-flag-title',
		defaultMessage: 'We couldn’t send your request',
		description: 'Title for the error flag shown when user tries sending a request for edit access',
	},
	errorContent: {
		id: 'grant-access-dialog.request-access-popup.error-flag-content',
		defaultMessage: 'We ran into a problem processing your request. Refresh and try again.',
		description:
			'Content for the error flag shown when user tries sending a request for edit access',
	},
	successIconLabel: {
		id: 'grant-access-dialog.request-access-popup.success-icon-label',
		defaultMessage: 'Success',
		description: 'The label for the success icon',
	},
	errorIconLabel: {
		id: 'grant-access-dialog.request-access-popup.error-icon-label',
		defaultMessage: 'Error',
		description: 'The label for the error icon',
	},
});

// This hook is intented to use Confluence flags instead of atlaskit flags.
export const useRequestPopupFlags = (flagHandler: FlagsStateContainer) => {
	const { formatMessage } = useIntl();

	const showSuccessFlag = () =>
		flagHandler.showSuccessFlag({
			id: `request-access-success-${Date.now()}`,
			title: formatMessage(i18n.successTitle),
			description: formatMessage(i18n.successContent),
			customIcon: (
				<CheckCircleIcon
					label={formatMessage(i18n.successIconLabel)}
					color={token('color.icon.success')}
					spacing="spacious"
				/>
			),
			isAutoDismiss: true,
		});
	const showErrorFlag = () =>
		flagHandler.showErrorFlag({
			id: `request-access-error-${Date.now()}`,
			title: formatMessage(i18n.errorTitle),
			description: formatMessage(i18n.errorContent),
			customIcon: (
				<ErrorIcon
					label={formatMessage(i18n.errorIconLabel)}
					color={token('color.icon.danger')}
					spacing="spacious"
				/>
			),
			isAutoDismiss: true,
		});

	return { showSuccessFlag, showErrorFlag };
};
