import gql from 'graphql-tag';

export const experimentalGrantAccessMutation = gql`
	mutation experimentalGrantAccessMutation(
		$contentId: String!
		$accessType: String!
		$accountId: String!
	) @experimental {
		experimentalGrantAccess(contentId: $contentId, accessType: $accessType, accountId: $accountId) {
			responseStatus
		}
	}
`;
