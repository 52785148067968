/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccessType {
  EDIT = "EDIT",
  VIEW = "VIEW",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RequestAccessMutation
// ====================================================

export interface RequestAccessMutation_requestPageAccess {
  displayName: string;
}

export interface RequestAccessMutation {
  requestPageAccess: RequestAccessMutation_requestPageAccess | null;
}

export interface RequestAccessMutationVariables {
  accessType: AccessType;
  pageId: string;
}
